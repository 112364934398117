import PhoneNumber, { parsePhoneNumber } from 'awesome-phonenumber';
import FormValidationConstants from '../constants/FormValidationConstants';
// import XRegExp from 'xregexp'
// import PhoneNumberConstants from '../constants/PhoneNumberConstants';
// validate : (value, allValues, props) => error [optional] #

export const required = (value) => {
  if ((value && (value.length === 0 || (typeof value === 'string' && !value.trim()))) || !value) {
    return FormValidationConstants.REQUIRED;
  }
};

// export const validEmail = (value) => {
//   return value && !FormValidationConstants.EMAIL_REGEXP.test(value) ? FormValidationConstants.INVALID_EMAIL : undefined;
// };

export const isValidEmail = (value) => {
  return !!value && FormValidationConstants.EMAIL_REGEXP.test(value);
};

export const isValidPassword = (value) => {
  if (value.trim() !== '') {
    return value.length >= 6 && value.length <= 19;
  }
  return true;
};

export const isValidString = (value, length = 200) => {
  if (value.trim() !== '') {
    return !hasContainSpecialCharacters(value) && value.length <= length;
  }
  return true;
};


export const isValidPoint = (value) => {
  const regex = /^[0-9]*$/;
  value = '' + value;
  if (value.trim() !== '') {
    return regex.test(value) && (parseInt(value) >= 0 && parseInt(value) <= 100);
  }
  return true;
}

export const isValidTGid = (value) => {
  const regex = /^[0-9]*$/;
  value = '' + value;
  if (value.trim() !== '') {
    return regex.test(value);
  }
  return true;
}

export const isNumber = (value) => {
  const regex = /^[0-9]*$/;
  if (value.trim() !== '') {
    return regex.test(value);
  }
  return true;
}

export const isFloatNumber = (value) => {
  const regex = /^\d+(\.\d+)?$/;
  const regex2 = /^\d+(\.)?$/;
  if (value.trim() !== '') {
    return regex.test(value) || regex2.test(value);
  }
  return true;
}

// export const validPhoneLength = (value) => {
//   return value && !/^(0|[1-9][0-9]{9})$/i.test(value) ? FormValidationConstants.INVALID_PHONE_LENGTH : undefined;
// };
  
// export const validUSPhone = (value) => {
//   const regex = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
//   return value && !regex.test(value) ? FormValidationConstants.INVALID_US_PHONE_NUMBER : undefined;
// };

// export const validLoginPhoneNumber = (value) => {
//   if (!value || !((new PhoneNumber(value)).getNumber(PhoneNumberConstants.E164))) {
//     return FormValidationConstants.INVALID_US_CA_PHONE_NUMBER;
//   }
// }

// export const validInstitutionPhone = (value) => {
//   const regex = /([+]?\d{1,2}[(.-\s]?)?(\d{2,3}[).-]?){2}\d{3,4}/;
//   return value && !regex.test(value) ? FormValidationConstants.INVALID_PHONE_NUMBER : undefined;
// };

// export const validatePhoneNumber = (value) => {
//   if (!value) return undefined;
//   if (!isValidPhoneNumber(value)) {
//     return FormValidationConstants.INVALID_US_CA_PHONE_NUMBER;
//   } else {
//     return undefined;
//   }
// };

//this function is adde to extract the phone validation functionality, and return true if the phone is valid, and false if it is not
// this could be the replacemente for the validatePhoneNumber functionality in the near future
export const isValidPhoneNumber = (value) => {
  if (!value) return false;
  const phoneNumber = new PhoneNumber(value);

  if (parsePhoneNumber(value).regionCode === 'US') {
    // 美国电话号码只校验10位数字即可
    const regex = /^[0-9]{10}$/;
    return regex.test(phoneNumber.getNumber('significant'));
  }
  if (phoneNumber.isValid(value)) {
    return true;
  }
  return phoneNumber.isValid(value);
}

// export const validateEmails = (value) => {
//   if (!value) {
//     return;
//   }
//   const emails = value.split(',');
//   if (emails && emails.length > 10) {
//     return FormValidationConstants.TOO_MANY_EMAILS_ENTERED;
//   }
//   for (let i = 0; i < emails.length; i++) {
//     if (!FormValidationConstants.EMAIL_REGEXP.test(emails[i].trim())) {
//       return FormValidationConstants.INVALID_EMAIL;
//     }
//   }
// };

// const maxEmailLength = (max) => (value) => {
//   if (!value) {
//     return undefined;
//   }
//   const emails = value.split(',').map(email => email.trim()).filter(email => email);
//   return emails.length > max ? `You can provide at most ${max} emails` : undefined;
// }

// export const maxEmailLength2 = maxEmailLength(2)


export const validUsername = (value) => {
  let error = false;
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9_-]+[a-zA-Z0-9]$/;

  if ((value && !value.trim()) || !value || value.length > 25
    || value.length < 3 || !regex.test(value) || !isNaN(Number(value))) {
    error = FormValidationConstants.INVALID_USERNAME;
  }
  return error;
}


// export const validateAllSpecialChars = (value) => {
//   const regex = /[&*=+@$^%./<>#{}|~]/;
//   if (regex.test(value)) {
//     return FormValidationConstants.SHOULD_NOT_HAVE_SPECIAL_CHAR;
//   }
// }

// export const validateSomeSpecialChars = (value) => {
//   const regex = /[@$^%<>#{}|~]/;
//   if (regex.test(value)) {
//     return FormValidationConstants.SHOULD_NOT_HAVE_SOME_SPECIAL_CHAR;
//   }
// }

// export const validateFewSpecialChars = (value) => {
//   const regex = /[{}~]/;
//   if (regex.test(value)) {
//     return FormValidationConstants.DESC_NOT_HAVE_SPECIAL_CHAR;
//   }
// }

export const hasContainSpecialCharacters = (str) => {
  // UTF surrogate pair
  const surrogatePair = RegExp(/[\uD800-\uDBFF][\uDC00-\uDFFF]/);
  return surrogatePair.test(str.trim());
}