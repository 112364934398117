export default {
      
  GLOBAL_ALERT                               : 'globalAlert',
   

  ROUTER_LIST                                : 'router_list',
  IDENTITY_TYPE_PASSPORT                     : 'passport',
  IDENTITY_TYPE_DRIVING_LICENCE              : 'driving_licence',
  IDENTITY_TYPE_IDENTITY                     : 'identity_document',

  PAY_ONLINE                                 : 'insurance_online',
  PAY_OFFLINE                                : 'insurance_offline',

  PAY_FEE                                    : 'insurance_fee',
  PAY_FAST_FEE                               : 'insurance_fast_fee',

  TRANSLATION_RESOURCE                       : 'translation_resource',


  COUNTDOWN_TIME_START                       : 'time_start',
  COUNTING_DOWN_STATUS                       : 'counting_down_status',
};


export const LOGIN_CONSTANTS = {
LOGGED_IN                         : 'LOGGED_IN',
LOGGED_OUT                        : 'LOGGED_OUT',
}

export const PAYMENT_TYPE_CONSTANTS = {
STRIPE                         : 'Stripe',
IBAN                           : 'Iban',
}