export default {
  REQUIRED                          : 'required',
  PLEASE_SELECT_GRADE               : 'please-select-grade',
  PLEASE_SELECT_SUBJECT             : 'please-select-subject',
  PLEASE_SELECT_SUB_SUBJECT         : 'please-select-sub-subject',
  PLEASE_SELECT_SCHOOL_CLASS_OWNER  : 'please-select-school-class-owner',
  INVALID_EMAIL                     : 'invalid-email',
  EMAIL_TAKEN                       : 'email-taken',
  DUPLICATE_EMAIL                   : 'duplicate-email',
  PLEASE_SELECT_RESOURCE_TITLE      : 'please-select-resource-title',
  PLEASE_ADD_DESCRIPTION            : 'please-add-resource-description',
  PLEASE_SELECT_RESOURCE_TYPE       : 'please-select-resource-type',
  INVALID_RESOURCE_TITLE            : 'invalid-resource-title',
  INVALID_DESCRIPTION               : 'invalid-description',
  INVALID_US_CA_PHONE_NUMBER        : 'invalid-phone-number',
  TOO_MANY_EMAILS_ENTERED           : 'too-many-emails-entered',
  PLEASE_ADD_SCHOOL_NAME            : 'please-add-school-name',
  PLEASE_CHOOSE_COUNTRY             : 'please-choose-country',
  PASSWORD_REQUIRED                 : 'password-required',
  INVALID_PASSWORD                  : 'invalid-password',
  INVALID_PASSWORD_CONFIRMATION     : 'invalid-password-confirmation',
  PASSWORD_DOESNT_MATCH             : 'doesnt-match',
  PLEASE_SELECT_LANGUAGE            : 'please-select-language',
  PLEASE_ENTER_PROFILE_URL          : 'please-enter-profile-url',
  INCORRECT_CODE                    : 'incorrect-code',
  PLEASE_ENTER_PASSWORD             : 'please-enter-password',
  INVALID_NAME                      : 'invalid-name',
  INVALID_USERNAME                  : 'invalid-username',
  INVALID_FIRST_NAME                : 'invalid-first-name',
  INVALID_LAST_NAME                 : 'invalid-last-name',
  INVALID_PROFILE_URL               : 'invalid-profile-url',
  PRIMARY_SECONDARY_EMAIL_SAME      : 'primary-secondary-email-same',
  USER_ALREADY_CONNECTED            : 'user-already-connected',
  INVALID_CODE                      : 'invalid-code',
  ALREADY_IN_CLASS                  : 'already-in-class',
  CHILD_IN_GROUP                    : 'child-in-group',
  INVALID_PHONE_LENGTH              : 'Invalid phone number, must be 10 digits',
  INVALID_US_PHONE_NUMBER           : 'Invalid US Phone number',
  MUST_BE_A_NUMBER                  : 'Must be a number',
  NUMBER_MUST_BE_POSITIVE           : 'number-must-be-positive',
  INVALID_PHONE_NUMBER              : 'please_enter_a_valid_phone_no',
  INVALID_WEBSITE                   : 'please_enter_a_valid_website',
  INVALID_ZIP                       : 'please_enter_a_valid_zip_code',
  INVALID_INSTITUTION_NAME          : 'please_enter_a_valid_name',
  EMPTY_IP_RANGE                    : 'empty_range',
  INVALID_START_IP                  : 'invalid_start_ip',
  INVALID_END_IP                    : 'invalid_end_ip',
  END_BEFORE_START                  : 'end_before_start',
  SELECTED_FUTURE_MONTH             : 'future-month-selected',
  INVALID_DEGREE_TITLE              : 'invalid-degree-title',
  INVALID_SCHOOL_NAME               : 'invalid-school-name',
  INVALID_DAY_HOURS                 : 'invalid-day-hours',
  INVALID_CLASS_PERIOD              : 'invalid-class-period',
  INVALID_CLASS_SUBJECT             : 'invalid-class-subject',
  INVALID_BUILDING_ROOM             : 'invalid-building-room',
  INVALID_RECIPIENTS                : 'no-receiver-err',
  PROFILE_URL_CANNOT_BE_NUMERIC     : 'profile-url-non-numeric',
  UNTITLED_IN_RESOURCE_TITLE        : 'untitled-in-resource-title',
  ALREADY_MEMBER_OF_GROUP           : 'already-member-of-group',
  INVALID_INPUT_TEXT                : 'html-tags-not-allowed',
  INVALID_MAXLENGTH_TITLE           : 'invalid-max-length-title',
  INVALID_MAXLENGTH_DESC            : 'invalid-max-length-desc',
  INVALID_MAXLENGTH_NOTE            : 'invalid-max-length-note',
  INVALID_LENGTH_TOO_LONG           : 'invalid-max-length-too-long',
  SHOULD_NOT_HAVE_SPECIAL_CHAR      : 'These characters are not allowed &, *, =, +, @, $, ^, %, ., /, <, >, #, {, }, |, ~',
  SHOULD_NOT_HAVE_SOME_SPECIAL_CHAR : 'These characters are not allowed @, $, ^, %, <, >, #, {, }, |, ~',
  SHOULD_NOT_HAVE_BAD_WORD          : 'These word(s) are not supported in',
  DUPLICATE_COLLECTION_NAME         : 'Duplicate Collection name.',
  DESC_NOT_HAVE_SPECIAL_CHAR        : 'These characters are not allowed {, }, ~',
  WORDS_ARE_NOT_ALLOWED             : 'These word(s) are not allowed',
  INVALID_MIN_WORDS_DESC            : 'invalid-min-words-length-desc',
  INVALID_MIN_CHARS_DESC            : 'invalid-min-char-length-desc',
  INVALID_SHARE_LINK                : 'invalid-share-link',
  INVALID_PRICE                     : 'invalid-price',
  INVALID_MIN_PRICE_USD             : 'invalid-min-price-usd',
  INVALID_MIN_PRICE_GBP             : 'invalid-min-price-gbp',
  INVALID_MIN_PRICE_JPY             : 'invalid-min-price-jpy',
  FULL_PRICE_LESS_THAN_PRICE        : 'full-price-less-than-price',
  INVALID_PRICE_DECIMAL_PLACE       : 'invalid-price-decimal-place',
  INVALID_PRICE_NUMERIC             : 'invalid-price-numeric',
  INVALID_PRICE_MAXLENGTH           : 'invalid-price-maxlenght-numeric',
  ALLOWED_TO_RATE_ONCE              : 'allowed-to-rate-once',
  INVALID_MAXLENGTH_SHORT_DESC      : 'invalid-max-length-short-desc',
  INVALID_MAXLENGTH_LONG_DESC       : 'invalid-max-length-long-desc',
  NOT_MEMBER_OF_ORG                 : 'not-member-of-org',
  EMAIL_REGEXP                      : /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i,
  EMAIL_IN_STRING_REGEXP            : /[A-Z0-9._'%+-]+@[A-Z0-9.-]+\.[A-Z]{2,63}$/i,
  PHONE_NUMBER_VERIFY_DAILY_RATE_LIMIT: 'phone-number-verify-daily-rate-limit',
  PHONE_NUMBER_VERIFY_CODE_ATTEMPT_LIMIT: 'phone-number-verify-code-attempt-limit',
  PHONE_NUMBER_VERIFY_INVALID_CODE  : 'phone-number-verify-invalid-code',
  PHONE_NUMBER_VERIFY_EXPIRED_CODE  : 'phone-number-verify-expired-code',
  PHONE_NUMBER_VERIFY_UNKNOWN_ERROR : 'phone-number-verify-unknown-error',
  NO_PERMISSION_TO_JOIN             : 'no-permission-to-join',
  MAXIMUM_NUMBER_REACHED            : 'maximum-number-reached',
};
