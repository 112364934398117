import React from 'react';
import Colors from '../constants/ColorConstants';

const LinkIcon = ({
  size = 24, 
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props 
}) => (
  <svg width={size} height={size} viewBox={viewBox} {...props}>
    <path
      fill={color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9 16H7.21c-2.106 0-4.006-1.535-4.193-3.634A4.003 4.003 0 0 1 7 8h2a1 1 0 1 0 0-2H7.21C4.16 6 1.44 8.187 1.05 11.211A6.007 6.007 0 0 0 7.001 18h2a1 1 0 1 0 0-2zm-1-4a1 1 0 0 0 1 1h6a1 1 0 1 0 0-2H9a1 1 0 0 0-1 1zm8.76-6c3.066 0 5.818 2.197 6.193 5.242A6.007 6.007 0 0 1 17 18h-2a1 1 0 1 1 0-2h2a4.003 4.003 0 0 0 3.983-4.366C20.796 9.535 18.896 8 16.79 8H15a1 1 0 0 1-1-1c0-.553.443-1 1.25-1h1.51z"
    />
  </svg>
);

export default LinkIcon;

