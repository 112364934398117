let oParam = {};
function genoParam() {
  oParam = {};
  if (!window.location) {
    return;
  }

  let sSearch = window.location.hash;
  if (sSearch === '' || sSearch === '#/') {
    sSearch = window.location.search; // on startup, there will be no hash, the params are in search string
  }
  let index = sSearch.indexOf('?');
  sSearch = index >= 0 ? sSearch.substr(index + 1) : '';

  let params = sSearch.split('&');
  params.forEach(function (paramExpr) {
    let param = paramExpr.split('=');
    let key = param[0];
    if (key) {
      let value = decodeURIComponent(param[1]);
      oParam[key] = value;
    }
  });
}
genoParam();
window.addEventListener && window.addEventListener('hashchange', genoParam);

let fn = (function (sParamName) {
  return oParam[sParamName] || '';
});
fn.get = function () {
  return oParam;
};
fn.getGenoParam = genoParam;
export const parseQuerystring = (query) => {
  const querystring = query.substring(1);
  const pairs = querystring.split('&');
  return pairs.reduce((queryObject, pair) => {
      const [ key, value ] = pair.split('=');
      queryObject[key] = decodeURIComponent(value);
      return queryObject;
  }, {});
};
export default fn;
