import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectCountdownReducer = state => state.get(ReducerNameConstants.COUNTDOWN);

export const selectCountingDownStatus = createSelector(
  [selectCountdownReducer],
  (countdownReducer) => {
    return countdownReducer && countdownReducer.get(StoreStateConstants.COUNTING_DOWN_STATUS);
  },
);

export const selectCountdownStartTime = createSelector(
  [selectCountdownReducer],
  (countdownReducer) => {
    return countdownReducer && countdownReducer.get(StoreStateConstants.COUNTDOWN_TIME_START);
  },
);