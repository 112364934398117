import React from 'react';

const RdsCancelIcon = ({
  size = 24, 
  viewBox = '0 0 24 24',
  className,
  ...props 
}) => (
  <svg width={size} height={size} viewBox={viewBox} className={className} {...props}>
        <path d="m6 6 12 12M6 18 18 6" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
  </svg>
);

export default RdsCancelIcon;
