import { parseQuerystring } from '../utils/Request';

let exec = function (code, options) {
    let mixpanel = window.mixpanel;
    if (typeof mixpanel != 'undefined') {
        let device = window.device || {};
        const url = window.location.hash;
        const queryParams = parseQuerystring(url.substring(url.indexOf('?')));
        const ad = queryParams.ad;
        let tmp = {
            'platform': device.platform,
            'deviceVersion': device.version,
            'deviceManufacturer': device.manufacturer,
            'deviceModel': device.model,
            url: window.location.protocol + "//" + window.location.host,
            ad
        };
        let myOptions = Object.assign({}, options, tmp);
        mixpanel.track && mixpanel.track(code, myOptions);
    }
};
const tracker = {
    trackNewsPage: function () {
        exec('Route to the news page');
    },
    trackTeslaHomePage: function () {
        exec('Route to the tesla homepage');
    },
    trackVehicleModel: function (vehicleModel) {
        exec('User selects the vehicle model', { vehicleModel });
    },
    trackCustomInfo: function (username) {
        exec('User submitted basic information', { username });
    },
    trackIdentificationPhoto: function (username) {
        exec('User submitted basic information', { username });
    },
    trackTakingSelfieVideo: function (username) {
        exec('User started taking selfie video', { username });
    },
    trackEndingSelfieVideo: function (username) {
        exec('System automatically stopped shooting the video', { username });
    },
    trackVideoAccessError: function (data) {
        exec("System camera permission error", {data});
    },
    trackVideoError: function (username) {
        exec("An error occurred in the system camera. Check whether the user's video is uploaded.", { username });
    },
    trackRestartTakingSelfieVideo: function (username) {
        exec('User restarted taking selfie video', { username });
    },
    trackUploadSelfieVideo: function (username) {
        exec('System automatically uploads the user video', { username });
    },
    trackAutoUploadAllUserinfo: function (username) {
        exec(`system automatically uploads all the user's information`, { username });
    },
    trackManualSubmitAllUserinfo: function (username) {
        exec('User manually submitted all user information', { username });
    },
    error: function (msg) {
        exec('ERROR', { msg });
    },
};
window.tracker = tracker;
export default tracker;
