import cookie from "react-cookies";
import LocalStorageConstants from '../constants/LocalStorageConstants';

class CookieUtil {
  static saveTrialProduct(product) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, product && JSON.stringify(product), { expires: time });
  }

  static loadTrialProduct() {
    let trialProduct = cookie.load(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, { path: '/' });
    trialProduct = trialProduct ? JSON.parse(trialProduct) : null;
    return trialProduct;
  }

  static clearTrialProduct() {
    cookie.remove(LocalStorageConstants.SELECTED_TRIAL_PRODUCT, { path: '/' });
  }

  static saveClickNum(num) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SHARE_CLICK_NUM, num.toString(), { expires: time });
  }

  static loadClickNum() {
    let num = cookie.load(LocalStorageConstants.SHARE_CLICK_NUM, { path: '/' });
    if (num && num !== '') {
      return parseInt(num);
    }
    return 0;
  }

  static saveShareProgress(progress) {
    const time = new Date(new Date().getTime() + 86400000);
    cookie.save(LocalStorageConstants.SHARE_PROGRESS, progress.toString(), { expires: time });
  }

  static loadShareProgress() {
    let progress = cookie.load(LocalStorageConstants.SHARE_PROGRESS, { path: '/' });
    if (progress && progress !== '') {
      return parseInt(progress);
    }
    return 0;
  }
}

export default CookieUtil;