import React from 'react';
import { Input } from 'reactstrap';
import propTypes from 'prop-types';

// === Components === //
// import EDSInputErrorAlert from './EDSInputErrorAlert';

// === Utils === //
// import { createBaseClassNames } from '../../../../../eds/utils/EDSUtils';


// const EDSInputErrorAlert = ({error, translate}) => {
//   return (
//     <small
//       className="qa-test-required-error required-error"
//     >
//       { translateError(error, translate) }
//     </small>
//   )
// }

const EDSInput  = ({
  input, label, placeholder, type, translate,
  className, autoFocus=false, ...otherProps
}) => {

  // const touched = meta && meta.touched
  // const error = meta && meta.error
  // const edsClassName = createBaseClassNames((input || otherProps), [className])

  return (
    <React.Fragment>
      { label && <div className={`eds-input-label ${className}`}>{label}</div> }
      <Input
        type={type}
        placeholder={label || placeholder}
        className={className}
        {...input}
        {...otherProps}
      />
    </React.Fragment>
  )
}


EDSInput.defaultProps = {
  type: 'text',
}

export default EDSInput;
