import { createSelector } from 'reselect';
import StoreStateConstants from '../constants/store/StoreStateConstants';
import ReducerNameConstants from '../constants/reducers/ReducerNameConstants';

const selectTranslationReducer  = state => state.get(ReducerNameConstants.TRANSLATION);

export const selectTranslations = createSelector(
  [selectTranslationReducer],
  (translationReducer) => {
    const translation = translationReducer && translationReducer.get(StoreStateConstants.TRANSLATION_RESOURCE);
    return translation?.toJS();
  },
);
