const PRODUCT_PREFIX = 'TESLA_';
export default {
  EDMODO_WEB_APP_STATE    : 'edmodo-web-app-state',
  CONFIG_MODE             : 'config_mode',
  CLEARED                 : 'cleared',
  LAST_LANGUAGE           : 'lastLanguage',
  NEW_TEACHER_ONBOARDING  : 'newTeacherOnboarding',
  TRANSLATOR_MODE_ON      : 'translatorModeOn',
  LAST_PAGE_VISITED       : 'lastPageVisited',
  FIRST_TIME_USER_EXP     : 'firstTimeUserExperience',
  USER_SELECTED_LANGUAGE  : 'userSelectedLanguage',
  ORDER_DETAIL            : `${PRODUCT_PREFIX}orderDetail`,
  SUBMIT_ORDER            : `${PRODUCT_PREFIX}submitOrder`,
  ST_PAYMENT_INTENT       : `${PRODUCT_PREFIX}st_payment_intent`,
  LAST_PAYMENT_INFO       : `${PRODUCT_PREFIX}last_payment_info`,
  FB_AD                   : `${PRODUCT_PREFIX}fb_ad`,
  DRIVER_ORDER_ID         : `${PRODUCT_PREFIX}driver_order_id`,
  DRIVER_ORDER_NU         : `${PRODUCT_PREFIX}driver_order_order`,
  SELECTED_TRIAL_PRODUCT  : `${PRODUCT_PREFIX}selected_trial_product`,
  SHARE_CLICK_NUM         : `${PRODUCT_PREFIX}share_click_num`,
  SHARE_PROGRESS          : `${PRODUCT_PREFIX}share_progress`,
  PURCHASE_EVENT          : `${PRODUCT_PREFIX}purchase_event`,
}
