import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const submitFBConversionEvent = (eventname, customdata, userdata, event_id = '', orderNu = '') => {
    return api.post(
        `${ApiEndpointConstants.FB_CONVERSION_EVENT}`,
        {eventname, customdata, userdata, event_id, orderNu,
            pixel: localStorage.getItem('pixelId'),
            fbqToken: localStorage.getItem('fbqToken'),
        },
    );
}
