import ActionConstants from '../constants/actions/ActionConstants';

export const updateCountingDownStatus = (status = false) => ({
  type: ActionConstants.UPDATE_COUNTING_DOWN_STATUS,
  payload: status,
});

export const updateCountDownStartTime = (timeStart) => ({
  type: ActionConstants.UPDATE_COUNTDOWN_START_TIME,
  payload: timeStart,
});
