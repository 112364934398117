import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './FB_EVENT_BASE_API';
import LocalStorageUtil from '../utils/LocalStorageUtil';

export const fetchPixelId = () => {
    return api.post(
        `${ApiEndpointConstants.PIXEL}`,
        {url: window.location.host}
        // {url: 'www.tesla-japan.biz'}
    );
}

export const sendCustomEvent = (eventname) => {
    return api.post(
        `${ApiEndpointConstants.CUSTOM_EVENT}`,
        {
            url: window.location.host,
            // url: 'www.tesla-japan.biz',
            ad: LocalStorageUtil.loadFbAdParams() || 'test',
            eventname,
        }
    );
}