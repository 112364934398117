import React from 'react';
import Colors from '../constants/ColorConstants';

const CameraIcon = ({
  size = 24,
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg viewBox="0 0 1024 1024" p-id="2602" width={size} height={size} {...props}>
    <path d="M601.6 256l76.8 96H896v480H128V352h201.6l76.8-96h195.2m30.4-64H376l-76.8 96H128c-35.2 0-64 28.8-64 64v480c0 35.2 28.8 64 64 64h768c35.2 0 64-28.8 64-64V352c0-35.2-28.8-64-64-64H708.8L632 192zM512 384c-106.4 0-192 85.6-192 192s85.6 192 192 192 192-85.6 192-192-85.6-192-192-192z m0 320c-70.4 0-128-57.6-128-128s57.6-128 128-128 128 57.6 128 128-57.6 128-128 128z" p-id="2603"></path>
    </svg>
);

export default CameraIcon