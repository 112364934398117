export default {
  KEY                  : 'key',
  AVATAR            : 'avatar',
  TRANSLATION_KEY      : 'translationKey',
  TRANSLATION_FALLBACK : 'translationFallback',
  INSERTIONS           : 'insertions',
  PARSE_HTML           : 'parseHtml',
  ALERT_TYPE           : 'alertType',
  TIMEOUT              : 'timeout',
  SUCCESS              : 'success',
  ERROR                : 'error',
  INFO                 : 'info',
  WARNING              : 'warning',
  DEFAULT_TIMEOUT      : 3000,
  LONG_TIMEOUT         : 5000,
};
