

import React from 'react';
import Colors from '../../constants/ColorConstants';

const VisaIcon = ({
    size = 70,
    color = Colors.brand.primary,
    viewBox = '0 0 24 24',
    ...props
}) => (
    //   <svg width={size} height={size} viewBox="0 0 16 16" fill="none" {...props}><path fillRule="evenodd" clipRule="evenodd" d="M6 2v.5H4.5v-1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-1V11h.5V2H6zM3 14V5h7v9H3zM1.5 4.5a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-8a1 1 0 0 1-1-1v-10z" fill="currentColor"></path></svg>
    <svg width={size} height={0.7 * size} viewBox="0 0 115 80" fill="none" {...props}>
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" fill="url(#paint0_linear)" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#E8E8E8" />
        <rect x="0.5" y="0.5" width="114" height="79" rx="9.5" stroke="#EDEDED" />
        <path d="M50.4333 54.1032H43.0616L47.6724 25.5176H55.0436L50.4333 54.1032Z" fill="#00579F" />
        <path d="M77.1561 26.217C75.7021 25.6386 73.3959 25 70.5443 25C63.2646 25 58.1383 28.8923 58.1068 34.4571C58.0464 38.5628 61.7771 40.8433 64.5674 42.2122C67.4194 43.6111 68.3889 44.5242 68.3889 45.7711C68.3599 47.6861 66.0843 48.5689 63.962 48.5689C61.0189 48.5689 59.4419 48.1137 57.0455 47.0483L56.0748 46.5916L55.0432 53.0084C56.7721 53.7982 59.9575 54.4991 63.2646 54.5298C70.9994 54.5298 76.0351 50.6977 76.0947 44.7675C76.1241 41.5134 74.1542 39.02 69.907 36.9825C67.3287 35.6745 65.7498 34.7926 65.7498 33.4543C65.78 32.2377 67.0853 30.9916 69.9957 30.9916C72.3922 30.9306 74.153 31.5082 75.4868 32.0862L76.1536 32.3897L77.1561 26.217Z" fill="#00579F" />
        <path d="M86.9537 43.9763C87.5608 42.3341 89.8968 35.9783 89.8968 35.9783C89.8662 36.0393 90.5027 34.3058 90.8667 33.2416L91.3819 35.7047C91.3819 35.7047 92.7778 42.5471 93.081 43.9763C91.9289 43.9763 88.4097 43.9763 86.9537 43.9763ZM96.0531 25.5176H90.3511C88.5927 25.5176 87.2568 26.0341 86.4982 27.8893L75.5486 54.1028H83.2834C83.2834 54.1028 84.5568 50.5746 84.8305 49.8148C85.6791 49.8148 93.2035 49.8148 94.2951 49.8148C94.5067 50.8184 95.1747 54.1028 95.1747 54.1028H102L96.0531 25.5176Z" fill="#00579F" />
        <path d="M36.9045 25.5176L29.6852 45.0102L28.8964 41.0569C27.5617 36.4953 23.3758 31.5391 18.7046 29.0748L25.3172 54.0729H33.1125L44.6994 25.5176H36.9045Z" fill="#00579F" />
        <path d="M22.9816 25.5176H11.1213L11 26.0952C20.2517 28.4673 26.379 34.1853 28.8964 41.0581L26.3181 27.9205C25.8936 26.0948 24.5892 25.5778 22.9816 25.5176Z" fill="#FAA61A" />
        <defs>
            <linearGradient id="paint0_linear" x1="57" y1="5" x2="57" y2="80" gradientUnits="userSpaceOnUse">
                <stop stop-color="#F5F5F5" />
                <stop offset="1" stop-color="white" />
            </linearGradient>
        </defs>
    </svg>
);

export default VisaIcon