export const BASE_URL = window.location.protocol + "//" + window.location.host;
// export const BASE_URL = 'https://tesla.rateose.store';
export const STORY_BASE_URL = (window.location.protocol + "//" + window.location.host).replace('www.','story.');


const Endpoint = {
    POST_TO_ADDRESS: '/api/index/address',
    ME: '/user/getUserinfo',
    
    STORY_DETAIL: '/index.php/api/Tiezi/getTiezi',
    STORY_COMMENT_LIST: '/index.php/api/Pinglun/getPinglunlisttiezi',

    UPLOAD: '/api/common/upload',
    ORDER: '/api/index/index',
    ORDER_UPDATE: '/api/index/updateOrder',
    ORDER_STATUS_UPDATE: '/api/index/webhooksfb',
    TRANSLATION: '/api/Urlrelation/getUrlrelationUrl',
    PIXEL: '/api/Urlrelation/getUrlrelationUrl',
    FB_CONVERSION_EVENT: '/api/facebook/EventsAll',
    CUSTOM_EVENT: '/api/Events/addevent',
    SUBMIT_ORDER:'/api/index/peng'
}
export default Endpoint;
