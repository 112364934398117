import { createStore, applyMiddleware } from 'redux';
import rootReducer from '../reducers/Index';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { composeWithDevTools } from 'redux-devtools-extension';
import LocalStorageUtil from '../utils/LocalStorageUtil';


const middlewares = [];

const configureStore = () => {
  const persistedState = LocalStorageUtil.loadState();
  const history = createBrowserHistory();
  const middleware = routerMiddleware(history);

  middlewares.push(thunk);
  middlewares.push(middleware);

  return createStore(
    rootReducer,
    persistedState,
    composeWithDevTools(applyMiddleware(...middlewares)),
  );
};

export default configureStore;
