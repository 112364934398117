import React from 'react';
import Colors from '../constants/ColorConstants';

const ArrowRightIcon = ({
  size = 24,
  color = Colors.brand.primary,
  viewBox = '0 0 24 24',
  ...props
}) => (
  <svg width={size} height={size} viewBox={viewBox} {...props}>
    <path d="M14.586 11l-3.293-3.293a1 1 0 011.414-1.414l5 5a1 1 0 010 1.414l-5 5a1 1 0 01-1.414-1.414L14.586 13H6a1 1 0 110-2h8.586z" fill={color}></path>
  </svg>
);

export default ArrowRightIcon