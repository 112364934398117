import React, { Component } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import queryString from "query-string";
import TeslaIcon from "../icons/TeslaIcon";
import TeslaImg from "../images/tesla.png";
import ShareModalFireworksIcon from "../icons/ShareModalFireworksIcon";
import LineIcon from "../images/scoial/icon_LINE.png";
import SmsIcon from "../images/scoial/icon_sms.png";
import WhatsAppIcon from "../images/scoial/icon_WhatsApp@2x.png";
import TelegramIcon from "../images/scoial/icon_Telegram.webp";
import MobileDetectUtil from "../utils/MobileDetectUtil";
import * as orderActionCreator from "../actions/orderActionCreator";
import * as fbEventActionCreator from "../actions/fbEventActionCreator";
import { Progress } from "reactstrap";
import LocalStorageUtil from "../utils/LocalStorageUtil";
import * as FBUtil from "../utils/FbUtil";
import StoreStateConstants from "../constants/store/StoreStateConstants";
import CookieUtil from "../utils/CookieUtil";
import XIcon from "../icons/XIcon";
import FireworksBackgroundImg from "../images/sahua.gif";
import RdsBackIcon from "../icons/RdsBackIcon";
import { sendCustomEvent } from "../apis/ConfigAPI";
import { generateRandomString } from "../utils/RandomGeneratorUtils";

const HTTP_REQUEST_URL =
  window.location.protocol + "//" + window.location.host + "/?ad=share";
class SuccessfullyPage extends Component {
  constructor(props, context) {
    super(props, context);
    const invitation_text = `Tesla one year free test drive rights! Enjoy an unlimited electric driving experience, apply now!`;
    const cacheClickNum = CookieUtil.loadClickNum();
    const cacheShareProgress = CookieUtil.loadShareProgress();
    this.state = {
      state: -1,
      email: "",
      phone: "",
      successfullyText: "",
      failedText: "",
      navList: [
        {
          title: "Line",
          img: LineIcon,
          url: `https://line.me/R/share?text=${encodeURIComponent(
            invitation_text + " " + HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Sms",
          img: SmsIcon,
          url: `sms:${
            MobileDetectUtil.judgeClient() === "iOS" ? "&" : "?"
          }body=${invitation_text} ${HTTP_REQUEST_URL}`,
          isClick: false,
        },
        {
          title: "WhatsApp",
          img: WhatsAppIcon,
          url: `https://api.whatsapp.com/send?text=${encodeURIComponent(
            `${invitation_text} ${HTTP_REQUEST_URL}`
          )}&url=${encodeURIComponent(HTTP_REQUEST_URL)}`,
          isClick: false,
        },

        {
          title: "Telegram",
          img: TelegramIcon,
          url: `https://telegram.me/share/url?text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Twitter",
          img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAA5FBMVEUAAAAAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+sAq+v////7/v/1/P7f9f0ete4HruzW8vxHw/ENsOyu5fmU3PdNxfEluO7v+v7m9/2o4/iE1/Zz0fQ4vu/p+P3N7/vH7fuk4fhpzvNdyvJTx/Ls+f7E7Pq+6vp71PUwu+8Usu0Ssey46Pm15/mg4Pib3veM2fY7v/AFVkUkAAAAJHRSTlMAC9+bR/R3Hwjs208U8ePWx72oWQK46dGUXBZwkGwpJG35SbcfS+rIAAAC6UlEQVRYw8XZaXeiMBQGYETUKlqX1qV2n3lBVNS6W612n27////MVNt6ILkJYM6Z56Pn8Aoh3iRXTSJrnNaK+UoCl2m9WqydGlktuph5UgWjemLGoqQdGqUECImScRj25o51COnHYW5zL5mGVDq5FzTvQEcg+kGguNRvBFZOyfOOcgghdyQbvThCigtHsl5AaIW6YPgyiCBDDuTZPiLZP+PnnVN58sRzbhnIILJMlvN+C9hBgX3Xcewkzsxn7Mg3w1M57CjnnTxl7KzsqS9Q4GCbF9OhgL4tuUkokfy5wTSUSH/f4gUUufha3wKP4Grk2LP55AlrTXYUN2uhAVa/xflsbm0MbgBMR2MwjHVgCazuK/ymA+vHW8+xZs9glNavJAFGp2H14PU8tDxsF6zE52sxwZr+u2LhfeqeN2+w+nifgGESZebq85p503vPHre2dd/iF50MWO7mLpaer/C57oCV0bQ6OJrWxnyKLxN/3p8OeOqaAZ7293X3q82DPfjybsH3i/gd32wvbYxuHr8/kAcmqdJ/7Z0jQ8cX6JBLQZE7hs9NxxK6JgKLWh4cq8bivSEM7BKBea0CjkdLZkwEVrQceIaywCtq+62B60GSZz+BoF2Cp9UWB96BDCTKf1/80BOQj0yV6+adIK9BPnFFq4Jy1bWpwFdQ8lqRinOcBvlK+qAU6V2X4LX0QIrTi/wH+cDDjmixN0BZ2qJJTZavOkjTNvWro9U/lwCa27u32bIgkJHthZsvTOlvyXbGJkidh4E/byHMg7le6Ik0963BTMAJhBIx/lbEXYxe2janTD9CrERtlvojTpyzhIxBb+eaY8dbDroupPRD4Yazv+zdtWe2PWt3x24LARwr3xKr3rQrP1aoPvioPpopPzyqPt6qPoArbxGobmKobrMobwQRUnlEkE9ppGyUZlpW2O6rIaSarHFqhmtImppUqowQLVPFTV3VbWfFjXHFrfv//+eC/+8PJCr5IH9//AVWNcpIN4xCFgAAAABJRU5ErkJggg==",
          url: `https://twitter.com/intent/tweet?lang=en&text=${invitation_text}&url=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
        {
          title: "Facebook",
          img: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAMAAAC5zwKfAAAArlBMVEUAAAA7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6A7V6D////o6/SXps3y9Pnz9fn5+v3d4u/AyeFUbKz9/v7n6/PM0+aerNGClMJug7nw8vjm6vOzvtqxvNlDXqRLmyLEAAAAJXRSTlMAC+v18uJuSBYI20/fIr24nHZZlh7RkVzVp+XIxnhGKdeonRQSNX6QYwAAAihJREFUWMOs1QduwkAUhOFxLxAILYROQGMgQAKBtPtfLCCKhBzv27X5DvBrJK93IZgO/Ubl2Xbp2HGr0vCHU+RnlZ5aTGk9lSzkEXQcZnA6gfE4v0ylsm8yM6rZFNm1CJrqHrV4dehoVqmt2oSo7dKA24ZaNKGhSQSFsE9j/RCZHmLmED8gw6jLXLqjjH0ec/L+3RjGzC0OkRL1WEAv/a0fWchj6jyzoDZuNF0W5N7+hVUWVr25X3gHdVxZHiX779XncnG0YQbPwkWNgrdtcvXKLLXrQFvqbRKdoH2Z6FOwTrSC9HFSlga+awbLp15AwTzRDDLAUYeCtXbwBQeWQ8HqnFrufqnmWABKlHydg2uKSgAalMzOwQ+KGgBi7eCcohgIec8gQwT3DQYYUGlxcDnXp8thRpWBdPUnKSvhKaiYBrdUqWBsGvyhyhi2SVA+jTZc0+COKi5oGtxT6a85M0phEIaC4BqrFWJEwYoI0lr0/lcskvyVWEyn0D3AfIUsO085+w43GRZ4Uc0CjW4s8KGGBTZqWWCrmQXO+PeFf7B8BajFgKGkLAm0vughYCh6LRxw0Z4rBwwqoqaAtXxKCljKJzMM0GQK6RhgF5kVqcAio4fPL6eZ3Pfj0dHzFh7gT1wR8BLjPW5K5U0uIoISxU1xVyT9mMIbe0Wzpsi09VD3DWd5wydxas8JSQsrUwdLXVo7w2KcVff/cFzwqfz5I9+23Iz7+aPScV5UGNMNcFXdPQAAAABJRU5ErkJggg==",
          url: `https://www.facebook.com/dialog/share?app_id=407682420960&display=popup&href=${encodeURIComponent(
            HTTP_REQUEST_URL
          )}`,
          isClick: false,
        },
      ],
      clickNum: cacheClickNum,
      progress: cacheShareProgress,
      showShareWarningDialog: false,
      showShareSuccessDialog: false,
      warningMessage: "",
    };
    this.goBack = this.goBack.bind(this);
    this.shareLinkToApp = this.shareLinkToApp.bind(this);
    this.updateOrderStatus = this.updateOrderStatus.bind(this);
    this.toggleShareWarningDialog = this.toggleShareWarningDialog.bind(this);
    this.toggleShareSuccessModal = this.toggleShareSuccessModal.bind(this);
    this.shareSuccessEvent = this.shareSuccessEvent.bind(this);
  }

  componentDidMount() {
    const cacheShareProgress = CookieUtil.loadShareProgress();
    if (cacheShareProgress === 5) {
      this.shareSuccessEvent();
    }

    // LocalStorageUtil.clearPurchaseEventFlag();
    // LocalStorageUtil.removeOrderDetail();
    // LocalStorageUtil.clearOrderId();
    // LocalStorageUtil.clearOrderNumber();

    this.updateOrderStatus();

    const { location } = this.props;
    const queryParams = queryString.parse(location.search);

    let successfullyText = "";
    if (queryParams.origin === "payment") {
      successfullyText =
        "Thank you for your order! To track the status of your order, we will send an email to %email. At the same time, we will send a text message to your phone number %phone within 5 business days, so be sure to reply.";
    } else {
      successfullyText =
        "Thank you for submitting! To track the status of your submission, we will send an email to %email. At the same time, we will send a text message to your phone number %phone within 5 business days, so be sure to respond.";
    }
    let failedText = "";
    if (queryParams.origin === "payment") {
      failedText = "Fail to pay! Try again later.";
    } else {
      failedText = "Submission failed! Please try again later.";
    }
    successfullyText = successfullyText
      .replace(
        "%email",
        `<span class="tw-text-normal-blue">${queryParams.email}</span>`
      )
      .replace(
        "%phone",
        `<span class="tw-text-normal-blue">${queryParams.phone}</span>`
      );
    this.setState({
      state: parseInt(queryParams.state),
      email: queryParams.email,
      phone: queryParams.phone,
      successfullyText,
      failedText,
    });
  }

  updateOrderStatus() {
    const { orderActions, location, fbEventActions } = this.props;
    const queryParams = queryString.parse(location.search);
    // const { cSiteUrl, paymentIntentId, subscriptionId, origin } = queryParams;
    const { state, orderNo, origin } = queryParams;

    if (origin === "payment") {
      const orderInfo = LocalStorageUtil.loadOrderDetail();
      const { email, phone, firstName, lastName, state, country, zipCode } =
        orderInfo;
      const eventId = generateRandomString(10);
      const eventParams = {
        content_ids: "test-driver",
        content_name: "Car test driver",
        content_type: "product",
        contents: [
          {
            id: "test-driver",
            title: orderInfo.vehicleModel,
            quantity: 1,
          },
        ],
        currency: "JPY",
        num_items: 1,
        value:
          orderInfo.insuranceType === StoreStateConstants.PAY_FEE
            ? localStorage.getItem("payment_amount")
            : localStorage.getItem("payment_amount_fast"),
      };
      sendCustomEvent("Purchase");
      FBUtil.trackFBEvent("Purchase", eventParams, eventId);
      fbEventActions &&
        fbEventActions.submitFBConversionEvent(
          "Purchase",
          eventParams,
          {
            em: email,
            ph: phone,
            fn: firstName,
            ln: lastName,
            ct: state,
            st: state,
            zp: zipCode,
            country: country,
          },
          eventId,
          LocalStorageUtil.loadOrderId()
        );
      setTimeout(() => {
        LocalStorageUtil.removeOrderDetail();
        LocalStorageUtil.clearStripePaymentIntent();
        LocalStorageUtil.clearOrderId();
        LocalStorageUtil.clearOrderNumber();
      }, 2000);
    }

    if (origin === "payment" && orderNo && state) {
      orderActions &&
        orderActions
          .updateOrderStatus({
            state: state,
            orderNo: orderNo,
          })
          .then((res) => {
            LocalStorageUtil.removeOrderDetail();
            LocalStorageUtil.clearStripePaymentIntent();
            LocalStorageUtil.clearOrderId();
            LocalStorageUtil.clearOrderNumber();
          })
          .catch((err) => console.error(err));

      setTimeout(() => {
        LocalStorageUtil.removeOrderDetail();
        LocalStorageUtil.clearStripePaymentIntent();
        LocalStorageUtil.clearOrderId();
        LocalStorageUtil.clearOrderNumber();
      }, 1000);
    }
  }

  goBack() {
    const { history } = this.props;
    history && history.goBack();
  }

  shareSuccessEvent() {
    // 添加分享成功事件
    setTimeout(() => {
      this.toggleShareSuccessModal();
    }, 1000);
  }

  shareLinkToApp(item) {
    sendCustomEvent("ClickTheShareButton");
    let { clickNum, progress } = this.state;
    if (clickNum < 7) {
      clickNum = clickNum + 1;
    }
    if (clickNum === 7) {
      this.shareSuccessEvent();
    }
    if (clickNum === 3) {
      this.setState({
        warningMessage:
          "Share to another friend. No statistics will be performed if you share to the same friend.",
        showShareWarningDialog: true,
      });
    } else if (clickNum === 5) {
      this.setState({
        warningMessage: "Group chats can also be shared.",
        showShareWarningDialog: true,
      });
    } else {
      if (progress < 5) {
        progress = progress + 1;
      }
    }
    this.setState({ clickNum, progress });
    CookieUtil.saveClickNum(clickNum);
    CookieUtil.saveShareProgress(progress);
    /**
         * 明天裂变的页面要加功能
           分享第一次 进度条20%
           分享第二次 进度条40%
           分享第三次 进度条不变还是40% 出现提示框 请分享给不同的好友，分享给同一个好友不做统计（別の友人にシェアしてください。同じ友人にシェアしても統計は行われません。）
           分享第四次 进度条60%
           分享第五次 进度条不变还是60% 出现提示 框 也可以分享群聊（グループチャットも共有できる）
           分享第六次  进度条80%
           分享第七次  进度条100%
        */
    window.open(item.url, "_blank"); // 打开新窗口
  }

  toggleShareWarningDialog() {
    this.setState({
      showShareWarningDialog: !this.state.showShareWarningDialog,
    });
  }

  toggleShareSuccessModal() {
    this.setState({
      showShareSuccessDialog: !this.state.showShareSuccessDialog,
    });
  }

  render() {
    const {
      state,
      successfullyText,
      failedText,
      navList,
      progress,
      warningMessage,
      showShareWarningDialog,
      showShareSuccessDialog,
    } = this.state;
    const { location } = this.props;
    const queryParams = queryString.parse(location.search);
    const origin = queryParams.origin;
    const progressPrecent = (progress / 5) * 100;

    const shareSuccessText =
      "Thank you for sharing! We will expedite the review process, so please be on the lookout for our phone calls and emails!";
    return (
      <div className="tw-w-full tw-h-full gotham-font tw-text-[#393c41]">
        {showShareWarningDialog && (
          <div className="tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.7)] tw-flex tw-items-center tw-justify-center tw-z-[1000]">
            <div className="tw-rounded-[15px] tw-w-[500px] phone:tw-w-[80%] animate__animated animate__bounceInDown tw-bg-white tw-px-[15px] tw-py-[15px]">
              <div className="tw-w-full tw-flex tw-justify-end">
                <XIcon color="#333" onClick={this.toggleShareWarningDialog} />
              </div>
              <div className="tw-mt-[12px] tw-text-center">
                {warningMessage}
              </div>
            </div>
          </div>
        )}
        {showShareSuccessDialog && (
          <div className="tw-w-full tw-h-full tw-fixed tw-left-0 tw-top-0 tw-bg-[rgba(0,0,0,0.3)] tw-flex tw-items-center tw-justify-center tw-z-[1000]">
            <img
              src={FireworksBackgroundImg}
              alt="background"
              className="tw-z-[1001] tw-absolute tw-left-[50%] tw-top-[50%] tw-w-full tw-h-[225px] "
              style={{ transform: "translate(-50%, -80%)" }}
            />
            <div
              className="tw-z-[1002] tw-rounded-[15px] tw-w-[500px] phone:tw-w-[75%] animate__animated animate__bounceInDown tw-bg-white tw-px-[15px] tw-py-[15px] tw-relative"
              style={{ boxShadow: "2px 6px 19px 11px rgba(0,0,0,.125)" }}
            >
              <ShareModalFireworksIcon
                size={110}
                className="tw-absolute tw-left-[-20px] tw-top-[-37px]"
                style={{ transform: "rotate(342deg)" }}
              />
              <div className="tw-w-full tw-flex tw-justify-end">
                <XIcon color="#333" onClick={this.toggleShareSuccessModal} />
              </div>
              <div className="tw-text-brand-primary tw-text-[22px] tw-font-bold tw-text-center">
                Share the success!
              </div>
              <div
                className="tw-mt-[12px] tw-text-center"
                dangerouslySetInnerHTML={{ __html: shareSuccessText }}
              >
                {}
              </div>
            </div>
          </div>
        )}
        <div className="tw-w-full tw-h-full phone:tw-h-full tw-overflow-scroll">
          <div className="tw-z-[100] tw-w-full tw-h-[80px] phone:tw-h-[60px] tw-flex tw-justify-between">
            <div className="tw-w-1/3 tw-h-full tw-flex tw-items-center tw-pl-[20px]">
              <RdsBackIcon onClick={this.goBack} />
            </div>
            <TeslaIcon size={150} color="#171a20" />
            <div className="tw-w-1/3"></div>
          </div>
          <div className="tw-w-[750px] phone:tw-w-full tw-mx-auto phone:tw-px-[20px] phone:tw-w-full">
            <img
              src={TeslaImg}
              alt="product"
              className="tw-w-[100px] tw-mx-auto tw-mt-[20px]"
            />
            {(origin === "payment" || origin === "submission") && (
              <React.Fragment>
                {state === 1 && (
                  <div className="tw-flex tw-flex-col tw-justify-between tw-items-center">
                    <div
                      className="tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-center  phone:tw-text-left tw-mt-[13px]"
                      dangerouslySetInnerHTML={{ __html: successfullyText }}
                    ></div>
                  </div>
                )}
                {state === 0 && (
                  <div className="tw-flex tw-flex-col tw-justify-between tw-items-center">
                    <div className="tw-w-full tw-text-[18px] phone:tw-text-[14px] tw-font-[500] tw-text-light-red tw-text-center  tw-mt-[20px]">
                      {failedText}
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}

            <div className="tw-w-full tw-mt-[0px]">
              <div className="phone:tw-text-[16px] tw-font-[500] tw-text-center tw-font-bold tw-mt-[20px]">
                <div>Share this campaign with your friends,</div>
                Let's expedite the review process!
              </div>
              <div className="phone:tw-mt-[15px] phone:tw-mb-[15px] tw-mt-[30px] tw-mb-[50px]  tw-flex tw-justify-center phone:tw-justify-between  tw-items-center tw-flex-wrap tw-mx-auto">
                {navList.map((item, index) => {
                  return (
                    <div
                      target="__blank"
                      className="tw-inline-block tw-w-[70px] tw-h-[70px] tw-mr-[20px] phone:tw-mr-0 phone:tw-w-[50px] phone:tw-h-[50px] tw-rounded-full tw-mt-[10px] tw-mr-[10px]"
                      onClick={() => this.shareLinkToApp(item, index)}
                      key={item.title}
                    >
                      <img
                        src={item.img}
                        alt="app_icon"
                        className="tw-w-full tw-h-full"
                      />
                    </div>
                  );
                })}
              </div>
              {progress > 0 && (
                <div className="tw-mt-[30px] tw-w-full tw-h-[20px] tw-bg-[#f7f7f7] tw-relative tw-rounded-[10px] tw-overflow-hidden">
                  <Progress
                    className="tw-absolute tw-left-0 tw-top-0 tw-text-white tw-h-full  tw-text-[12px]"
                    animated
                    bar
                    color="success"
                    value={progressPrecent}
                  >{`${progressPrecent}%`}</Progress>
                </div>
              )}
            </div>
            {/* <div className='tw-w-full tw-flex tw-justify-center tw-items-center tw-mt-[30px] tw-text-white tw-mt-[20px]'>
                            <RDSButton
                                color="primary"
                                onClick={this.backToHome}>
                                <span>家に帰る</span>
                            </RDSButton>
                        </div> */}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    orderActions: bindActionCreators(orderActionCreator, dispatch),
    fbEventActions: bindActionCreators(fbEventActionCreator, dispatch),
  };
}

export default connect(null, mapDispatchToProps)(SuccessfullyPage);
