import ApiEndpointConstants from '../constants/api/ApiEndpointConstants';
import api from './API';

export const getFullAddressByZipcode = (zipcode) => {
    return api.post(ApiEndpointConstants.POST_TO_ADDRESS, {
        zipcode
    })
}

export const getStateByZipcode = (zipcode) => {
    return api.post(ApiEndpointConstants.POST_TO_ADDRESS, {
        zipcode, parts: 1
    })
}