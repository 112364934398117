const PagePathConstants = {
  ROOT_PAGE                     : "/",
  TESLA                         : "/tesla",
  TESLA_ORDER                   : "/tesla_order",
  VEHICLE_MODEL                 : "/vehicle_model",
  CUSTOM_INFO                   : "/custom_info",
  DOCUMENT_INFO                 : "/document_info",
  SELFIE_INFO                   : "/selfie_info",
  ORDER_CONFIRM                 : "/order_confirm",
  SUCCESS_CALLBACK              : "/success_callback",
  SUBMISSION_CALLBACK           : "/submission_callback",
  PAYMENT_INFO                  : "/payment_info",
  SEND_CODE                     : "/send_code",
}

export default PagePathConstants;
