import * as StoryAPI from '../apis/StoryAPI';

export const getStoryInfoById = (id) => {
    return function (dispatch, getState) {
        return new Promise((resolve, reject) => {
            StoryAPI.getStoryInfoById(id)
                .then((res) => {
                    if (res.status === 200) {
                        return resolve(res.data.data);
                    }
                })
                .catch((err) => {
                    return reject(err);
                });
        });
    };
}