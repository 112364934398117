import ApiEndpointConstants, { BASE_URL } from "../constants/api/ApiEndpointConstants";
import * as UploadAPI from "../apis/UploadAPI";
import { showErrorGlobalAlert } from './globalAlertActionCreator';
import axios from "axios";

export const uploadImgToGetUrl = (base64Str) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      UploadAPI.uploadImg(base64Str)
        .then((res) => {
          const imgUrl = BASE_URL + res.data.data.url;
          return resolve(imgUrl);
        })
        .catch((err) => {
          if (err && err.response && err.response.data) {
            dispatch(showErrorGlobalAlert(err.response.data.msg));
          } else {
            dispatch(showErrorGlobalAlert('img-upload-error'));
          }
          return reject(err);
        });
    });

  };
};

export const uploadVideoToGetUrl = (file) => {
  return function (dispatch, getState) {
    return new Promise((resolve, reject) => {
      let formData = new FormData();
      formData.append("file", file);
      axios({
        url: BASE_URL + ApiEndpointConstants.UPLOAD,
        method: 'post',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
        .then((res) => {
          const videoUrl = BASE_URL + res.data.data.url;
          return resolve(videoUrl);
        })
        .catch((err) => {
          return reject(err);
        });
    });
  }
};