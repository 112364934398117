import { parseQuerystring } from '../utils/Request';

export const trackFBEvent = (code, options, eventId) => {
    let callback = () => {
        const url = window.location.hash;
        const queryParams = parseQuerystring(url.substring(url.indexOf('?')));
        const ad = queryParams.ad;
        let tmp = {
            url: window.location.href,
            ad
        };
        let myOptions = Object.assign({}, options, tmp);
        window.fbq('track', code, myOptions, {eventID: eventId});
    }
    if (typeof fbq !== 'undefined') {
        callback();
    } else {
        setTimeout(function () {
            trackFBEvent(code, options)
        }, 2000);
    }
}
